/* https://github.com/Semantic-Org/Semantic-UI/issues/4805 */

/* // ButtonLink/style.css */

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    box-shadow: 0 0 !important;
    padding: 1px;
    margin: 0px;
    text-align: left;
}

.ui.blue.basic.button.link, .ui.blue.basic.button.link:hover, .ui.blue.basic.button.link:focus {
    box-shadow: 0 0 !important;
    padding: 1px; 
    margin: 0px;
    text-align: left;
}